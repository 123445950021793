import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [ 
  {
    path: '',
    redirectTo: 'BusinessCollection',
    pathMatch: 'full'
  },
  {
    path: 'BusinessReview',
    loadChildren: () => import('./features/ebr-setup/business-review/business-review.module').then(m => m.BusinessReviewModule),
    canActivate:[MsalGuard]
  },
  {
    path: '',
    loadChildren: () => import('./features/ebr-setup/business-selection/business-selection.module').then(m => m.BusinessSelectionModule),
    canActivate:[MsalGuard]
  }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ scrollOffset: [0, 0], scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
